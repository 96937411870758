import * as React from 'react'
import { Link } from 'gatsby'
import {
  container,
  heroImage,
  introLink
} from './intro.module.css'

const Intro = () => {
  return(
    <div className={heroImage} id="home">
      <article className={container}>
        <p>本ウェブサイトおよび本ウェブサイト上で提供される情報は、Horiko Capital Management LLC（以下"HCM"）が運用助言するファンド及び有価証券の取引を推奨する目的、または勧誘する目的で提供されるものではありません。詳細は <Link to="/legal" className={introLink}>利用規約/法的留意及び開示事項</Link> をご覧下さい。</p>
        <p>日本居住の個人の皆様は、弊社運用「HC Focus Fund」を組み入れた投資信託 <a href="https://www.rakuten-sec.co.jp/api/ss/detail/?ID=JP90C000D4C5" className={introLink} target="_blank" rel="noreferrer"> 新ホリコ・フォーカス・ファンド（愛称：新自由の女神)</a>をご覧下さい。</p>
      </article>
    </div>
  )
}

export default Intro
