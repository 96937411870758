import * as React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Intro from "../components/intro"
import News from "../components/news"
import About from "../components/about"
import Team from "../components/team"
import Contact from "../components/contact"

const IndexPage = () => {
  return (
    <>
    <Seo title="ホリコ・キャピタル・マネジメント - HORIKO CAPITAL MANAGEMENT LLC"/>
    <Layout pageTitle="ホリコ・キャピタル・マネジメント - HORIKO CAPITAL MANAGEMENT LLC">
      <Intro />
      <News />
      <About />
      <Team />
      <Contact />
    </Layout>
    </>
  )
}

export default IndexPage
