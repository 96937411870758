import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import {
  h2,
  sectionFlex,
  sectionTitle,
  sectionContents,
  sectionContentsItem,
  pContents,
  contentsItem,
} from './about.module.css'

const About = () => {
  return(
    <div id="about">
      <article className={sectionFlex}>
        <section className={sectionTitle}>
          <h2 className={h2}>ABOUT HCM</h2>
          <p className={pContents}>ホリコ・キャピタル・マネジメントはニューヨークに拠点を置く投資顧問会社です。</p>
        </section>
        <section className={sectionContents}>
          <div className={sectionContentsItem}>
            <div className={contentsItem}>
              <p>社名</p>
              <p>ホリコ・キャピタル・マネジメント<br />
                Horiko Capital Management LLC</p>
            </div>
            <div className={contentsItem}>
              <p>本社所在地</p>
              <p>1600 Broadway, 21st Floor<br/>New York, NY 10019</p>
            </div>
            <div className={contentsItem}>
              <p>設立</p>
              <p>2000年4月</p>
            </div>
            <div className={contentsItem}>
              <p>代表者</p>
              <p>堀古 英司</p>
            </div>
            <div className={contentsItem}>
              <p>登録</p>
              <p>SEC登録投資顧問業者</p>
            </div>
            <div className={contentsItem}>
              <p>事業内容</p>
              <p>資産運用業務<br />
                投資アドバイザリー業務<br />
                投資調査業務</p>
            </div>
          </div>
          <div className={sectionContentsItem}>
            <StaticImage
              alt="about"
              src="../images/about.png"
            />
          </div>
        </section>
      </article>
    </div>
  )
}

export default About
