import * as React from 'react'
import { Helmet } from "react-helmet"

const Seo = () => {
  return (
    <Helmet
      htmlAttributes={{ lang: 'ja' }}
      title='ホリコ・キャピタル・マネジメント - HORIKO CAPITAL MANAGEMENT LLC'
      meta={[
        {
          name: `description`,
          content: `ニューヨークに拠点を置く投資顧問会社、ホリコ・キャピタル・マネジメントの公式ウェブサイトです。`,
        },
        {
          property: `og:title`,
          content: 'ホリコ・キャピタル・マネジメント - HORIKO CAPITAL MANAGEMENT LLC',
        },
        {
          property: `og:description`,
          content: 'ニューヨークに拠点を置く投資顧問会社、ホリコ・キャピタル・マネジメントの公式ウェブサイトです。',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: '../images/company_logo.png'
        },
        {
          property: `og:url`,
          content: "https://horikocapital.com/",
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
      ].concat([])}
    />
  )
}

export default Seo
