import * as React from 'react'
import {
  h2,
  sectionFlex,
  sectionTitle,
  sectionContents,
  contentsItem,
  link,
  gray,
} from './team.module.css'

const Team = () => {
  return(
    <div id="team">
      <article className={sectionFlex}>
        <section className={sectionTitle}>
          <h2 className={h2}>INVESTMENT TEAM</h2>
        </section>
        <section className={sectionContents}>
          <div className={contentsItem}>
            <p>堀古 英司 <br />
              (ホリコ ヒデジ)<br />
              <span className={gray}>最高運用責任者 / President & CEO</span>
            </p>
            <p>東京銀行（現三菱UFJ銀行）為替資金部ドル・円ディーラー、部長代理、同ニューヨーク支店バイスプレジデントを歴任した後、ニューヨークにてファンドマネジャーとしてヘッジファンドの運用に携わる。関西学院大学経済学部卒、米ニューヨーク大学大学院（ビジネススクール）にて金融工学専攻、経営学修士(MBA)。 著書に<a href="https://www.amazon.co.jp/dp/4844373757" target="_blank" rel="noreferrer" className={link}>『リスクを取らないリスク』（クロスメディア・パブリッシング）。</a></p>
          </div>
          <div className={contentsItem}>
            <p>マーティ スブラマニアム 博士 <br/>(Marti Subramanyam, Ph.D)<br />
              <span className={gray}>顧問 / Adviser</span>
            </p>
            <p>米マサチューセッツ工科大学(MIT)金融・経済学博士。現在、米ニューヨーク大学ビジネススクールにて金融工学・資本市場・国際金融担当教授として教鞭を取る傍ら数多くの社外取締役、投資銀行の顧問として活躍。NHKの「マネー革命」において紹介された米金融工学界の第一人者。</p>
          </div>
        </section>
      </article>
    </div>
  )
}

export default Team
