import React, { useState } from "react";
import { StaticImage } from 'gatsby-plugin-image'
import {
  h2,
  sectionFlex,
  sectionTitle,
  sectionContents,
  sectionContentsItem,
  aboutContents,
  inputForm,
  inputSelect,
  inputText,
  formButton,
} from './contact.module.css'

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");

  const handleChange = event => {
    switch (event.target.name) {
      case "name":
        setName(event.target.value);
        break;
      case "email":
        setEmail(event.target.value);
        break;
      case "country":
        setCountry(event.target.value);
        break;
      case "message":
        setMessage(event.target.value);
        break;
      default:
        console.log("key not found");
    }
  }

  const canSubmit = () => {
    if (name === "") return true;
    if (email === "") return true;
    if (country === "") return true;
    if (message === "") return true;

    return false;
  }

  return(
    <div id="contact">
      <article className={sectionFlex}>
        <section className={sectionTitle}>
          <h2 className={h2}>CONTACT</h2>
          <p>資産運用のご相談、講演や取材・執筆の依頼はこちらのフォームよりお送り下さい。<br/>
          原則として翌営業日以内に回答差し上げます。</p>
          <p><small>* すべて入力必須項目です。</small></p>
        </section>
        <section className={sectionContents}>
          <div className={sectionContentsItem}>
          <div className={aboutContents}>
            <form name="Contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/success/">
              <input type="hidden" name="form-name" value="Contact" />
              <input type="hidden" name="bot-field" />
              <div>
                <p><label htmlFor="name">お名前</label><br/>
                <input type="text" name="name" id="name" className={inputForm} value={name}
                onChange={handleChange} required/></p>
              </div>
              <div>
                <p><label htmlFor="email">Eメール（半角）</label><br/>
                <input type="email" name="email" pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" className={inputForm} value={email} id="email"
                onChange={handleChange} required/></p>
              </div>
              <div>
                <p><label htmlFor="country">居住国</label><br/>
                <input type="country" name="country" className={inputForm} value={country} id="country"
                onChange={handleChange} required/></p>
              </div>
              <div>
                <p>
                  <label htmlFor="request">ご依頼内容</label><br />
                  <select name="request" size="1" className={inputSelect} id="request">
                    <option value="asset_management">資産運用</option>
                    <option value="lecture">講演</option>
                    <option value="media">執筆・依頼</option>
                  </select>
                </p>
                <p>
                  <label htmlFor="message">内容詳細</label><br />
                  <textarea name="message" className={inputText} id="message" value={message} onChange={handleChange} required>
                  </textarea>
                </p>
              </div>
              <p>
                <button type="submit" className={formButton} disabled={canSubmit()}>送信</button>
              </p>
            </form>
          </div>
          </div>
          <div className={sectionContentsItem}>
            <StaticImage
              alt="about"
              src="../images/contact.jpg"
            />
          </div>
        </section>
      </article>
    </div>
  )
}

export default Contact
