import * as React from 'react'
import {
  h2,
  sectionFlex,
  sectionTitle,
  // sectionContents,
  link,
} from './news.module.css'

const News = () => {
  return(
    <div id="news">
      <article className={sectionFlex}>
        <section className={sectionTitle}>
          <h2 className={h2}>NEWS</h2>
          <p>堀古によるレポート <a href="https://plaza.rakuten.co.jp/isWallStreet/" target="_blank" rel="noreferrer" className={link}>『ウォール街から』</a> は <a href="https://plaza.rakuten.co.jp/isWallStreet/" target="_blank" rel="noreferrer" className={link}>こちらから</a> ご覧ください。<br/>
          メディア出演・講演などの最新情報はSNS (<a href="https://www.facebook.com/horikocapital/" target="_blank" rel="noreferrer" className={link}>Facebook</a>, <a href="https://twitter.com/horikocapital" target="_blank" rel="noreferrer" className={link}>Twitter</a>) からご覧いただけます。
          </p>
        </section>
        {/* <section className={sectionContents}>
          <div>
            <a class="twitter-timeline" data-width="1200" data-height="500" data-theme="light" href="https://twitter.com/horikocapital?ref_src=twsrc%5Etfw">Tweets by horikocapital</a>
          </div>
          <p><small>※ 表示されない場合は <a href="https://www.facebook.com/horikocapital/" target="_blank" rel="noreferrer" className={link}>こちら</a> からご覧ください。</small></p>
        </section> */}
      </article>
    </div>
  )
}

export default News
